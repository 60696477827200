//Imports
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import MainBanner from '../components/sections/MainBanner';
// import RegisterNowBanner from '../components/sections/RegisterNowBanner';
// import LocationsContent from '../components/sections/LocationsContent';
import OneColumnContent from '../components/sections/OneColumnContent';
import TwoColumnContent from '../components/sections/TwoColumnContent';
import AgendaOverview from '../components/sections/AgendaOverview';
// import AgendaContent from '../components/sections/AgendaContent';
import SpeakersList from '../components/sections/SpeakersList';
import SponsorsList from '../components/sections/SponsorsList';
// import SponsorLogosContent from '../components/sections/SponsorLogosContent';
// import FormContent from '../components/sections/FormContent';

//Component
const Page = ({ data }) => {
    /* -- Variables -- */
    const sections = data.allWordpressPage.edges[0].node.acf.page_sections_page;
    const seo = data.allWordpressPage.edges[0].node.yoast_meta;
    const seoRobots = data.allWordpressPage.edges[0].node.acf.search_engine;
    const seoImage = data.allWordpressPage.edges[0].node.acf.social_image;
    const headScripts = data.allWordpressAcfOptions.edges[0].node.options.third_party_head_scripts;
    const bodyScripts = data.allWordpressAcfOptions.edges[0].node.options.third_party_body_scripts;

    return (
        <Layout seo={seo} seoRobots={seoRobots} seoImage={seoImage} headScripts={headScripts} bodyScripts={bodyScripts}>
            {sections.map(section => {
                if (section.__typename === 'WordPressAcf_main_banner') {
                    return <MainBanner key={section.id} data={section} />
                } else if (section.__typename === 'WordPressAcf_one_column_content') {
                    return <OneColumnContent key={section.id} data={section} />
                } else if (section.__typename === 'WordPressAcf_two_column_content') {
                    return <TwoColumnContent key={section.id} data={section} />
                } else if (section.__typename === 'WordPressAcf_agenda_overview_content') {
                    return <AgendaOverview key={section.id} data={section} />
                } else if (section.__typename === 'WordPressAcf_speakers_content') {
                    return <SpeakersList key={section.id} data={section} />
                } else if (section.__typename === 'WordPressAcf_sponsors_content') {
                    return <SponsorsList key={section.id} data={section} />
                }

                return <div key={section.id}>{section.__typename}</div>;
            })}    
        </Layout>
    )
}

export default Page;

export const query = graphql`
    query($slug: String!) {
        allWordpressAcfOptions {
            edges {
                node {
                    options {
                        third_party_head_scripts {
                            script
                            src
                            attributes
                        }
                        third_party_body_scripts {
                            script
                            src
                            attributes
                        }
                    }
                }
            }
        }
        allWordpressPage(filter: {slug: {ne: "dummy", eq: $slug}}) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                    acf {
                        search_engine
                        social_image {
                            source_url
                        }
                        page_sections_page {
                            ...on WordPressAcf_main_banner {
                                ...mainBanner
                            }
                            ...on WordPressAcf_one_column_content {
                                ...oneColumnContent
                            }
                            ...on WordPressAcf_two_column_content {
                                ...twoColumnContent
                            }
                            ...on WordPressAcf_agenda_overview_content {
                                ...agendaOverview
                            }
                            ...on WordPressAcf_speakers_content {
                                ...speakersList
                            }
                            ...on WordPressAcf_sponsors_content {
                                ...sponsorsList
                            }
                        }
                    }
                }
            }
        }
    }
`;