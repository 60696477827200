//Imports
import React from 'react';
import Img from 'gatsby-image';

import moduleStyle from './SponsorsList.module.scss';

//Component
const SponsorsList = ({data}) => {
    /* -- Return -- */
    return (
        <div id={data.section_name}  className={moduleStyle.module}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 dangerouslySetInnerHTML={{__html: data.title}} />
                        <div className={moduleStyle.list}>
                            <div className="row align-items-center">
                                {data.sponsors.map( (sponsors, index) => {
                                    return (
                                        <div key={index} className="col-6 col-sm-4 col-md-3">
                                            <Img className={moduleStyle.sponsor} fluid={sponsors.sponsor.localFile.childImageSharp.fluid} alt={sponsors.sponsor.alt_text} imgStyle={{ objectFit: 'contain' }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

//Export
export default SponsorsList;