//Imports
import React from 'react';
import Img from 'gatsby-image';
import classnames from 'classnames';
import BackgroundImage from 'gatsby-background-image-es5';

import moduleStyle from './TwoColumnContent.module.scss';

//Component
const TwoColumnContent = ({data}) => {
    /* -- Variables -- */
    let moduleTitle = '';
    let mainMedia = '';
    let contentOrder = '';
    let mediaOrder = '';
    let backgroundImage = '';
    let classNames = classnames(moduleStyle.module, data.text_color==="dark" ? moduleStyle.darkText : moduleStyle.lightText);

    /* -- Conditions -- */
    if (data.variation === 'content-right') {
        contentOrder = 'col-12 col-md-5 order-md-2';
        mediaOrder = 'col-12 col-md-7 order-md-1';
    } else if (data.variation === 'content-left') {
        contentOrder = 'col-12 col-md-7 order-md-1';
        mediaOrder = 'col-12 col-md-5 order-md-2';
    } else if (data.variation === 'content-left-featured') {
        contentOrder = 'col-12 col-md-5 order-md-1';
        mediaOrder = 'col-12 col-md-7 order-md-2';
    }

    if (data.title !== null) {
        moduleTitle = `<div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="moduleTitle">${data.title}</h2>
                </div>
            </div>
        </div>`;
    }

    if (data.main_image !== null) {
        mainMedia = <Img fluid={data.main_image.localFile.childImageSharp.fluid} alt={data.main_image.alt_text} />;
    } else {
        mainMedia = <div dangerouslySetInnerHTML={{__html: data.media}} className={moduleStyle.media} />;
    }

    (data.background_image!==null) ? backgroundImage = <div className={`${moduleStyle.bg} sectionBG`}><BackgroundImage fluid={data.background_image.localFile.childImageSharp.fluid} fadeIn={false}></BackgroundImage></div> : backgroundImage='';

    /* -- Return -- */
    return (
        <div id={data.section_name} className={classNames} style={{ backgroundColor: data.background_color}}>
            {backgroundImage}
            <div dangerouslySetInnerHTML={{__html: moduleTitle}} />
            <div className="container">
                <div className="row align-items-center">
                    <div className={contentOrder}>
                        <div dangerouslySetInnerHTML={{__html: data.content}} className={moduleStyle.content} />
                    </div>
                    <div className={mediaOrder}>
                        {mainMedia}
                    </div>
                </div>
            </div>
        </div>
    )
}

//Export
export default TwoColumnContent;