//Imports
import React from 'react';
import Img from 'gatsby-image';

import moduleStyle from './AgendaOverview.module.scss';

//Component
class AgendaOverview extends React.Component {
    /* -- State -- */
    state = { selectedDay: 0 };

    /* -- Changes the day selected -- */
    selectDate = (e) => {
        if (e.currentTarget.getAttribute('data-date')) {
            this.setState({ selectedDay: parseInt(e.currentTarget.getAttribute('data-date'))});
        } else {
            this.setState({ selectedDay: parseInt(e.currentTarget.value)});
        }
    }

    /* -- Return -- */
    render() {
        /* -- Variables -- */
        const data = this.props.data;

        /* -- Render -- */
        return (
            <div id={data.section_name} className={moduleStyle.module}>
                <div className="container">
                    {/* -- Title -- */}
                    <div className="row">
                        <div className="col-12">
                            <h2 dangerouslySetInnerHTML={{__html: data.title}} />
                        </div>
                    </div>
                    {/* -- Agenda Date & Image -- */}
                    <div className={`row ${moduleStyle.dateContainer}`}>
                        {data.days.map( (days, index) => {
                            return (
                                <div key={index} className="col-12 col-sm-4 d-flex justify-content-center">
                                    <div data-date={index} className={`${moduleStyle.dayDesktop} ${this.state.selectedDay === index ? moduleStyle.activeDay : ''}`} onClick={this.selectDate}>
                                        <span className={moduleStyle.day}>{days.day}<br /></span>
                                        <span className={moduleStyle.date}>{days.date}</span>
                                    </div>
                                </div>
                            );
                        })}
                        <div className={`col-12 ${moduleStyle.dayMobile}`}>
                            <select onChange={this.selectDate}>
                                {data.days.map( (days, index) => {
                                    return <option key={index} value={index}>{days.day}: {days.date}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        {data.days.map( (days, index) => {
                            return (
                                <div key={index} className={`${moduleStyle.agenda} ${this.state.selectedDay === index ? moduleStyle.activeImage : ''} col-12`}>
                                    <div className={moduleStyle.agendaImage}>
                                        <Img fluid={days.agenda_image.localFile.childImageSharp.fluid} alt={days.agenda_image.alt_text} fadeIn={false} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

//Export
export default AgendaOverview;