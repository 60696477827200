//Imports
import React from 'react';
import Img from 'gatsby-image';

import moduleStyle from './SpeakersList.module.scss';

//Component
const SpeakersList = ({data}) => {
    /* -- Return -- */
    return (
        <div id={data.section_name}  className={moduleStyle.module} style={{backgroundColor: data.background_color}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* -- Title -- */}
                        <h2 dangerouslySetInnerHTML={{__html: data.title}} />
                    </div>
                    <div className="col-12">
                        <div className="row">
                            {/* -- Speaker List -- */}
                            {data.speakers.map((speakers) => {
                                const speaker = speakers.speaker.map((speaker, index) => {
                                    /* -- Variables -- */
                                    let featured;
                                    /* -- Checks if featured -- */
                                    if (speaker.acf.featured) {
                                        featured = 'col-12 col-md-6 featuredImg';
                                    } else {
                                        featured = 'col-6 col-md-4 col-lg-3';
                                    }

                                    /* -- Render -- */
                                    return (
                                        <div key={index} className={`${featured} ${moduleStyle.speakerContainer}`}>
                                            <div className={moduleStyle.speaker}>
                                                <div className="speakerImage">
                                                    <Img fluid={speaker.acf.headshot.localFile.childImageSharp.fluid} alt={speaker.acf.headshot.alt_text} />
                                                </div>
                                                <div className={moduleStyle.info}>
                                                    <span className={moduleStyle.name}>
                                                        {speaker.post_title}
                                                    </span>
                                                    <span className={moduleStyle.title}>
                                                        {speaker.acf.title}
                                                    </span>
                                                    <span className={moduleStyle.company}>
                                                        {speaker.acf.company}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                });
                                return speaker;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//Export
export default SpeakersList;