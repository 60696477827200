//Imports
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image-es5';

import moduleStyle from './MainBanner.module.scss';

//Component
const MainBanner = ({data}) => {
    /* -- Main Banner -- */
    if (data.variation === 'main') {
        /* -- Return -- */
        return (
            <BackgroundImage id={data.section_name} className={moduleStyle.module} fluid={data.background.localFile.childImageSharp.fluid} fadeIn={false}>
                <div className="container">
                    <div className={`row ${moduleStyle.row}`}>
                        {/* -- Logo --*/}
                        <div className={`col-md-7 d-flex align-items-center ${moduleStyle.logoContainer}`}>
                            <BackgroundImage className={moduleStyle.logoBackground} fluid={data.banner_background.localFile.childImageSharp.fluid} fadeIn={false} />
                            <div className={moduleStyle.logo}>
                                <Img fluid={data.logo.localFile.childImageSharp.fluid} alt={data.logo.alt_text} fadeIn={false} loading="eager" />
                            </div>
                        </div>
                        {/* -- Info --*/}
                        <div className={`col-12 col-md-5 ${moduleStyle.contentContainer}`}>
                            <div className={moduleStyle.content}>
                                <h1>{data.heading}</h1>
                                <p className={moduleStyle.date}>{data.date}</p>
                                <p className={moduleStyle.location}>{data.location}</p>
                                <Link to={data.button_link} className={moduleStyle.btn} aria-label={`${data.button_text} - ${data.heading}`}>
                                    {data.button_text}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        );
    /* -- Center Banner -- */
    } else if (data.variation === 'center') {
        /* -- Return -- */
        return (
            <BackgroundImage id={data.section_name} className={moduleStyle.moduleCenter} fluid={data.main_background.localFile.childImageSharp.fluid} fadeIn={false}>
                <div className="container">
                    <div className="row">
                        {/* -- Info --*/}
                        <div className="col-12">
                            <h1 style={{backgroundColor: data.highlighted_background_color}}>{data.highlighted_heading}</h1>
                            <span className={moduleStyle.centerHeading} dangerouslySetInnerHTML={{__html: data.main_heading}} />
                            <span className={moduleStyle.centerSubheading} dangerouslySetInnerHTML={{__html: data.subheading}} />
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        )
    }
};

//Export
export default MainBanner;