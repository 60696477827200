//Imports
import React from 'react';
import BackgroundImage from 'gatsby-background-image-es5';
import classnames from 'classnames';

import moduleStyle from './OneColumnContent.module.scss';

//Component
const OneColumnContent = ({data}) => {
    /* -- Conditional Return -- */
    if (data.variation === 'image') {
        return (
            <div id={data.section_name} className={moduleStyle.module}>
                <a href={data.link} className={moduleStyle.image} target="_blank" rel="noopener noreferrer">
                    <BackgroundImage fixed={data.main_image.localFile.childImageSharp.fixed}>
                    </BackgroundImage>
                </a>
            </div>
        )
    } else if (data.variation === 'text-button') {
        /* -- Variables -- */
        let btnHTML = '';
        let backgroundImage = '';
        let classNames = classnames(moduleStyle.module, data.text_color==="dark" ? moduleStyle.darkText : moduleStyle.lightText);
        
        /* -- Return only the html being used -- */
        (data.button_text!==null) ? btnHTML = <a href={data.button_link} className={moduleStyle.btn}>{data.button_text}</a> : btnHTML='';
        (data.background_image!==null) ? backgroundImage = <div className={`${moduleStyle.bg} sectionBG`}><BackgroundImage fluid={data.background_image.localFile.childImageSharp.fluid}></BackgroundImage></div> : backgroundImage='';

        /* -- Return -- */
        return (
            <div id={data.section_name} className={classNames} style={{ backgroundColor: data.background_color }}>
                {backgroundImage}
                <div className="container">
                    <div className="row">
                        <div className={`col-12 ${moduleStyle.textButton}`}>
                            <h2>{data.title}</h2>
                            {btnHTML}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//Export
export default OneColumnContent;